import React from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import InvalidIcon from "../../assets/images/svg-cross.svg";

const Invalid = () => {
  return (
    <div>
      <div className="main-page" style={{ background: "#fdbd1b" }}>
        <div class="container-outer" id="expired_outer">
          <div class="banner-inner-text col-md-4">
            <CRow>
              <CCol xs={12}>
                <CCard>
                  <CCardBody className="py-5" style={{ textAlign: "center" }}>
                    <img src={InvalidIcon} width={50} />
                    <p className="mt-3 mb-0 success_title">
                      Unauthorized Company
                    </p>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invalid;
